import axios from "axios";

export function apiPost(path, data, onData, onError){
    axios.post(path, data)
    .then((data)=>{ onData(data.data); })
    .catch((err)=>{if (onError) onError(err)});
}

export function apiGet(path, onData, onError){
    axios.get(path)
    .then((data)=>{ onData(data.data); })
    .catch((err)=>{if (onError) onError(err)});
}

