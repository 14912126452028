import { secondsToHHMMSS } from "../../functions/dataHandler";
import { groupBy } from "../../functions/objects_arrays";

export function dateDiffDuration(start, end) {
  return new Date(end) - new Date(start);
}

export function calculateAverage(count, value) {
  try {
    const seconds = Math.floor(value / count);
    const result = new Date(seconds).toISOString().slice(11, 19);
    return result;
  }
  catch (e) { return 0; }
}
export function getAverageOfArray(arr) {
  let tot = 0;
  let count = 0;
  arr.forEach((e) => {
    if (!isNaN(e)) {
      tot += e;
      count++;
    }
  })
  return calculateAverage(count, tot);
}

export function getAverageDuration(data, startField, endField) {
  if (!data) return "00:00:00";
  if (data.length === undefined) return "00:00:00";
  if (data.length === 0) return "00:00:00";
  if (data === null) return "00:00:00";
  const durationList = data.map(d => (dateDiffDuration(d[startField], d[endField])));
  return getAverageOfArray(durationList);
}


export function cleanCallTypeText(ccString) {
  ccString = ccString.toLowerCase();
  ccString = ccString.replace(" ", "");
  return ccString;
}


export function getCareDelivered(callsList) {
  try {
    let careList = [];
    callsList.forEach(c=>{
      const careDelivered = handleCareData(c["care_delivered"]);
      //add careDelivered to careList by combining the two arrays
      if (careDelivered!==null) careDelivered.forEach(cd=>careList.push(cd));
      
      if (careDelivered!==null) careList.push(careDelivered);
      if (c["Care Delivered"]!==undefined) careList.push(c["Care Delivered"]);
    });
    
    careList = careList.filter(f=>(f!==null));
      
    return careList;
    }
    catch(e){
      console.log("Getting Care Error", e);
      return[];}
  }

function getClicksFromObject(val) {
  try {
    return Object.values(JSON.parse(val));
  } catch (e) { return val; }
}

export function getCarer(callsList) {
  try {
    const careoObj = {};
    callsList.forEach(c => {
      const cd = (c["Carer"] !== undefined) ? c["Carer"] : c["carer"];
      if (cd !== undefined) careoObj[cd] = "1";
    });

    const rtn = Object.keys(careoObj).join(",");
    return (rtn !== undefined) ? rtn : [];
  }
  catch (e) { return []; }
}

function reorderArrayByDateTime(arrayOfObjects, fieldName) {
  return arrayOfObjects.sort((a, b) => {
    const dateA = new Date(a[fieldName]);
    const dateB = new Date(b[fieldName]);

    return dateA - dateB;
  });
}

function handleCareData(careData){
  if (careData===undefined) return null;
  if (careData===null) return null;
  if (careData==="") return null;
  if (careData==="{}") return null;
  if (careData==="['']") return null;
  if ((careData.length===1)&&(careData[0]==='')) return null;
  try {
    const careObj = careData.map(c=>JSON.parse(c));
    return careObj;
  }
  catch(e){
    console.log("CareDataError", e);
    //console.log("CareData", careData);
    return null;
  }
}

function reverserderArrayByDateTime(arrayOfObjects, fieldName) {
  return arrayOfObjects.sort((a, b) => {
    const dateA = new Date(a[fieldName]);
    const dateB = new Date(b[fieldName]);

    return dateB - dateA;
  });
}

function getUniqueValues(inputList) {
  // Use Set to store unique values
  let uniqueValues = new Set(inputList);

  // Convert Set back to array
  return Array.from(uniqueValues);
}

export function processJourneyFromCallsList(journeyID, journeyList) {

  //journeyList = reorderArrayByDateTime(journeyList);

  try {
    const firstCall = journeyList[0];
    const lastCall = journeyList[journeyList.length - 1];
    return {
      AccessoryType: "",
      duration: calculateDuration(firstCall.start, lastCall.end),
      durationSeconds: calculateDateDifferenceInSeconds(firstCall.start, lastCall.end),
      start: firstCall.start,
      end: lastCall.end,
      callText: getUniqueValues(journeyList.map(j => j.callType)).join(","),
      CallSource: getUniqueValues(journeyList.map(j => j.DeviceType)).join(","),
      DeviceType: getUniqueValues(journeyList.map(j => j.DeviceType)).join(","),
      journeyRef: journeyID,
      proreact: (firstCall.callType === "Attendance") ? "Proactive" : "Reactive",
      room: firstCall.room,
      roomCode: (firstCall.txCode) ? firstCall.txCode.split(".")[0] : "",
      callType: firstCall.callType,
      callCode: (firstCall.txCode) ? firstCall.txCode.split(".")[1] : "",
      zone: (firstCall.zone !== undefined) ? firstCall.zone : firstCall.Zone,
      calls: journeyList,
      care_delivered: getCareDelivered(journeyList),
      Carer: getCarer(journeyList),
      StartDay: getDayNameFromUTC(firstCall.start),
      StartDate: getDateFromUTC(firstCall.start),
      StartMonth: getMonthFromUTC(firstCall.start),
      EmergencyNon: (firstCall.callType === "Emergency") ? "Emergency" : "Non Emergency"
    };
  }
  catch (e) {
    console.log("JourneyCreationError", e);
    return [];
  }

}

function getMonthFromUTC(utcDate) {
  const date = new Date(utcDate);
  const monthIndex = date.getUTCMonth() + 1;
  return monthIndex;
}

function calculateDateDifferenceInSeconds(dateString1, dateString2) {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);


  // Calculate the difference in milliseconds
  let differenceInMilliseconds = date2 - date1;
  if (differenceInMilliseconds < 0) differenceInMilliseconds = date1 - date2;

  // Convert the difference to seconds
  const differenceInSeconds = differenceInMilliseconds / 1000;

  return differenceInSeconds;
}

function getDateFromUTC(utcDate) {
  const date = new Date(utcDate);
  const dateWithoutTime = date.toISOString().split('T')[0];
  return dateWithoutTime;
}


export function getStartDate(obj) {
  let strReturnDate = "";
  if (obj.hasOwnProperty("start")) {
    const value = obj["start"];
    if (value instanceof Date && !isNaN(value.valueOf()) && value.toISOString().endsWith('Z')) {
      strReturnDate = value;
    }
  }
  if (strReturnDate !== "") return strReturnDate;

  if (obj.hasOwnProperty("startFullDate")) {
    const value = obj["startFullDate"];
    if (value instanceof Date && !isNaN(value.valueOf()) && value.toISOString().endsWith('Z')) {
      strReturnDate = value;
    }
  }
  if (strReturnDate !== "") return strReturnDate;

  return convertToFullDate(obj.start);
}

export function convertToFullDate(expectedDateValue) {
  const regexTimeFormat = /^(\d{2}):(\d{2}):(\d{2})$/;

  if (regexTimeFormat.test(expectedDateValue)) {
    const currentDate = new Date();
    const [hours, minutes, seconds] = expectedDateValue.split(':');
    currentDate.setHours(hours, minutes, seconds, 0);
    return currentDate;
  } else {
    return expectedDateValue;
  }
}

export function getProperty(obj, propName) {
  if (obj.hasOwnProperty(propName)) {
    return obj[propName];
  }
  return "";
}

export function getEndDate(obj) {
  let strReturnDate = "";
  if (obj.hasOwnProperty("end")) {
    const value = obj["end"];
    if (value instanceof Date && !isNaN(value.valueOf()) && value.toISOString().endsWith('Z')) {
      strReturnDate = value;
    }
  }
  if (strReturnDate !== "") return strReturnDate;

  if (obj.hasOwnProperty("end_date_time_utc")) {
    const value = obj["end_date_time_utc"];
    if (value instanceof Date && !isNaN(value.valueOf()) && value.toISOString().endsWith('Z')) {
      strReturnDate = value;
    }
  }
  if (strReturnDate !== "") return strReturnDate;

  return convertToFullDate(obj.end);
  /* 
 let strReturnDate = "";
 
 strReturnDate = getProperty(obj, "end");
 if (isValidDate(strReturnDate)===true) return strReturnDate;
 strReturnDate = getProperty(obj, "end_date_time_utc");
 if (strReturnDate!=="") return strReturnDate;
 
 return convertToFullDate(strReturnDate);
 */
}

function isValidDate(dt) {
  dt = new Date(dt);
  if (dt.toString() === "Invalid Date") return false;
  if (!(dt instanceof Date) || isNaN(dt)) {
    return false;
  }
  else {
    return true;
  }
}

export function cleanStartEndDates(arr) {
  return arr.map(obj => {
    obj.start = convertToFullDate(getStartDate(obj));
    obj.end = convertToFullDate(getEndDate(obj));
    return obj;
  });
}

export function calculateDurations(arr) {
  return arr.map(obj => {
    obj.duration = calculateDuration(obj.start, obj.end);
    obj.durationSeconds = calculateDateDifferenceInSeconds(obj.start, obj.end);
    return obj;
  });
}

export function processCallsList(cData) {
  cData = cleanStartEndDates(cData);
  cData = calculateDurations(cData);

  cData = reorderArrayByDateTime(cData, "start");

  const nJourney = groupBy(cData, "journeyRef");
  let journeys = [];

  for (const [key, callList] of Object.entries(nJourney)) {
    journeys.push(processJourneyFromCallsList(key, callList));
  }

  journeys = reverserderArrayByDateTime(journeys, "start");

  return journeys;
}

export function processCallsListForLiveData(cData) {
  cData = cleanStartEndDates(cData);
  cData = calculateDurations(cData);
  setSenseType(cData)
  setDeviceType(cData)
  // console.log(cData)
  // cData = setFallData(cData);

  cData = reorderArrayByDateTime(cData, "start");

  const nJourney = groupBy(cData, "journeyRef");
  let journeys = [];

  for (const [key, callList] of Object.entries(nJourney)) {
    journeys.push(processJourneyFromCallsList(key, callList));
  }

  journeys = reverserderArrayByDateTime(journeys, "start");

  return journeys;
}

function setSenseType(cData) {
  cData.map(item => {
    if (item.AccessoryType === "Fall emergency")
      item.SenseType = "Fall emergency"
    else if (item.AccessoryType === "Fall risk")
      item.SenseType = "Fall risk"
    else
      item.SenseType = "Accessory"

  })
}

function setDeviceType(cData) {
  cData.map(item => {
    let DeviceType;
    if ((item.callType === 'Sense' || item.callType === 'Accessory') && (!item.dv_deviceType || item.dv_deviceType === null)) {
      DeviceType = 'Sense';
    } else if (!item.dv_deviceType || item.dv_deviceType === null) {
      DeviceType = 'Room unit';
    } else {
      DeviceType = item.dv_deviceType;
    }
    item.DeviceType = DeviceType
  })
}

function getDayNameFromUTC(utcDate) {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const date = new Date(utcDate);
  const dayIndex = date.getUTCDay();

  return daysOfWeek[dayIndex];
}

function calculateDuration(startUtc, endUtc) {
  var formattedDuration = ''
  try {
    const startTime = new Date(startUtc);
    const endTime = new Date(endUtc);

    // Calculate the difference in milliseconds
    const durationInMillis = endTime - startTime;

    // Convert the duration to hours, minutes, and seconds
    let hours = String(Math.floor(durationInMillis / 3600000)).padStart(2, '0'); // 1 hour = 3600000 milliseconds
    let minutes = String(Math.floor((durationInMillis % 3600000) / 60000)).padStart(2, '0'); // 1 minute = 60000 milliseconds
    let seconds = String(Math.floor((durationInMillis % 60000) / 1000)).padStart(2, '0'); // 1 second = 1000 milliseconds

    if (hours < 0) hours = "00";
    if (minutes < 0) minutes = "00";
    if (seconds < 0) seconds = "00";

    // Format the result
    formattedDuration = `${hours}:${minutes}:${seconds}`;
  }
  catch (ex) {
    console.log('enddate', endUtc)
  }

  return formattedDuration;
}