import IMG_ALL from '../images/call_types/all.png'; 
import IMG_EMERGENCY from '../images/call_types/emergency.png'; 
import IMG_CALL from '../images/call_types/call.png'; 
import IMG_ACCESSORY from '../images/call_types/accessory.png'; 
import IMG_ASSISTANCE from '../images/call_types/assistance.png'; 
import IMG_ATTENDANCE from '../images/call_types/attendance.png'; 
import IMG_CARE from '../images/call_types/care.png'; 
import IMG_VISIT from '../images/call_types/visit.png';
import { lCase } from '../functions/string'; 
import IMG_INBED from '../images/sense/inbed.png';
import IMG_CHAIR from '../images/sense/chair.png';
import IMG_ONFLOOR from '../images/sense/onfloor.png';
import IMG_BATHROOM from '../images/sense/shower.png';
import IMG_ROOM from '../images/sense/room.png';
import IMG_FALL from '../images/sense/fall.png';
import IMG_HBEAT_ACTIVE from '../images/heartbeat/active.png'
import IMG_HBEAT_INACTIVE from '../images/heartbeat/inactive.png'

export function GetCallTypeImage({imgString, className}) {
    return (<img className={`live_dashboard_call_type_img ${className}`}  src={getCallTypeImage(imgString)} /> );
}

export function GetCallTypeImagePanel({imgString}) {
    return (<img className='panel_call_type'  src={getCallTypeImage(imgString)} /> );
}

export function GetCallTypeImagePhone({imgString}) {
    return (<img className='phone_call_type'  src={getCallTypeImage(imgString)} /> );
}

export function GetSenseImage({imgString}) {
    return (<img className='sense_dashboard'  src={getSenseImage(imgString)} /> );
}

export function GetHeartbeatIcons({imgString}) {
    return (<img className='heartbeat'  src={getHeartbeatIcons(imgString)} /> );
}

function getCallTypeImage(imgString) {
    if ((imgString===undefined)||(imgString==="")) return;
    if (imgString==="") return;
    imgString = lCase(imgString);
    imgString = imgString.replace(" ", "");
    if (imgString==="emergency") return IMG_EMERGENCY;
    if (imgString==="call") return IMG_CALL;
    if (imgString==="attendance") return IMG_ATTENDANCE;
    if (imgString==="accessory") return IMG_ACCESSORY;
    if (imgString==="sense") return IMG_ACCESSORY;
    if (imgString==="assistance") return IMG_ASSISTANCE;
    if (imgString==="carecall") return IMG_CARE;
    if (imgString==="visit") return IMG_VISIT;
    return IMG_ALL;
}


function getSenseImage(imgString) {
    if ((imgString===undefined)||(imgString==="")) return;
    imgString = lCase(imgString);
    imgString = imgString.replace(" ", "");
    if (imgString==="bed") return IMG_INBED;
    if (imgString==="chair") return IMG_CHAIR;
    if (imgString==="manonground") return IMG_ONFLOOR;
    if (imgString==="bathroom") return IMG_BATHROOM;
    if (imgString==="room") return IMG_ROOM;
    if (imgString==="fall") return IMG_FALL;
    return IMG_ALL;
}

function getHeartbeatIcons(imgString) {
    if ((imgString===undefined)||(imgString==="")) return;
    if (imgString === "YES") {
        return IMG_HBEAT_ACTIVE;
      } else if (imgString === "NO") {
        return IMG_HBEAT_INACTIVE;
      }
}