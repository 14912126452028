import {
  MaterialReactTable,
  MRT_EditActionButtons,
  useMaterialReactTable,
  MRT_ColumnDef
} from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here
import {
  Box,
  Button,
  ListItemIcon,
  MenuItem,
  Menu,
  Typography,
  lighten,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  DialogContentText,
  Dialog,
  Pagination,
  PaginationItem,
} from '@mui/material';

import { AccountCircle, Send, Edit, Key, Link } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import AQModal from '../AQModal/AQModal';
import { useState } from 'react';
import EditZoneProfile from '../EditZoneProfile/EditZoneProfile';
import NewZoneProfilePage from '../NewZoneProfilePage/NewZoneProfilePage';
import NewUserPage from '../NewUserPage/NewUserPage';
import NewIntegration from '../../Pages/Settings/Integration/NewIntegration';
import CreateAPI from '../../Pages/Settings/Integration/CreateAPI';
import NewGroups from '../../Pages/Settings/Groups/NewGroups';
import EditGroup from '../../Pages/Settings/Groups/EditGroups';
import DeleteGroup from '../../Pages/Settings/Groups/DeleteGroups';
import ResetPasswordForm from '../ResetPasswordForm/ResetPasswordForm';
import { apiPost } from '../../data/apidata';
import UserDetails from '../UserDetails/UserDetails';
import ExportHandler from '../../Pages/Exports/export_handle';
import ConnectUserEdit from '../../Pages/Settings/UserManagement/connectUserEdit';
import { useSnackbar } from 'notistack';
import AQToolTip from '../ToolTip/ToolTip';
// import Link from '@mui/icons-material';
// import Menu from '@mui/material';
import { NourishResidentEdit } from '../../Pages/Nourish/NourishResidentEdit';
import { NourishResidentDetails } from '../../Pages/Nourish/NourishResidentDetails';
import { UserDetailsResident } from './ResidentsTable';

import UserEdit from '../../Pages/Teams/UserEdit/UserEdit';
const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

const _tableHeaderFontSize = "12.5px";
const _tableBodyFontSize = "12.5px";



const AQDataTable = ({ data, columns, settingsType, onUpdateRefreshPath, dataRefresh, siteID, options }) => {
  const [rowCount, setRowCount] = useState(0);

  function getCurrentDateTimeForFilename() {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    return `${year}_${month}_${day}_${hours}_${minutes}`;
  }

  function getFilename(section) {
    return `${getTitleFromSettingsType(settingsType)}_${getCurrentDateTimeForFilename()}`;
  }

  const [modalElement, setModalElement] = useState(null);
  const [modalHeight, setModalHeight] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleExportRows = (rows) => {

    function runDownload(exdata) {
      csvConfig["filename"] = getFilename("");
      const csv = generateCsv(csvConfig)(cleanData(exdata, settingsType));
      download(csvConfig)(csv);
    }

    const rowData = rows.map((row) => row.original);
    if ((rows !== null) && (rows.length > 0)) {
      runDownload(rowData, settingsType);
    }
    else {
      runDownload(data, settingsType);
    }

  };

  function cleanData(data, tableType) {

    if (tableType === "settings_groups") {
      return data.map(d => {
        return {
          "Group Name": d.groupName,
          "Group ID": d.groupID,
          'Group Units': d.groupUnit
        }
      });
    }

    if (tableType === "settings_integrations") {
      return data.map(d => { return { "Integration": d.name, "API Key": d.apikey } });
    }

    if (tableType === "team_management") {
      return data.map(d => { return { "Title": d.title, "First name": d.firstName, "Surname": d.surname, "Email": d.email, "Role": d.role, } });
    }

    if (tableType === "live_calls") {
      console.log("datatoclean", data);
      const rtnData = data.map(d => {
        return {
          "Call type": d.callType,
          "Start date": d.start,
          "End date": d.end,
          "Duration": d.duration,
          "Room": d.room,
          "Zone": d.zone,
          "Carer": d.Carer,
          "Care delivered": getCareText(d.care_delivered)
          //(d.care_delivered.length===0) ? "" : d.care_delivered.map(c=>(c["CallType"]==="Comments")?c["callValue  "]:c["CallType"]).join("-")
        }
      });
      console.log("returndatatoclean", rtnData);
      return rtnData;

    }

    if (tableType === "reporting_data") {
      console.log(data);
      return data.map(d => {
        return {
          "Call type": d.callType,
          "Start date": d.start,
          "End date": d.end,
          "Duration": d.duration,
          "Room": d.Room,
          "Zone": d.zone,
          "Carer": d.Carer,
          "Care delivered": getCareText(d.care_delivered),
          //(d.care_delivered.length===0) ? "" : d.care_delivered.map(c=>(c["CallType"]==="Comments")?c["callValue  "]:c["CallType"]).join("-")
        }
      });

    }

    return data;
  }

  function getCareText(care) {
    //(d.care_delivered.length===0) ? "" : d.care_delivered.map(c=>(c["CallType"]==="Comments")?c["callValue  "]:c["CallType"]).join("-")
    return care;
  }


  const handleExportData = () => {
    csvConfig["filename"] = getFilename("");
    const csv = generateCsv(csvConfig)(cleanData(data, settingsType));
    download(csvConfig)(csv);
  };

  const defaultSettings = {
    ...MasterTableSettings,
    renderTopToolbarCustomActions: ({ table }) => (<ExportButton tableData={table} />),
  }

  const TopMenu = (props) => {
    return (<div className='flex-row'>{props.children}<ExportButton tableData={props.tableData} /> </div>);
  }

  function addDefault(obj) { return { ...defaultSettings, ...obj } }


  function getTableSettings(strSettings) {

    if (strSettings === "live_calls") return addDefault(table_settings_live_calls);
    if (strSettings === "devices") return addDefault(table_settings_devices);
    if (strSettings === "user_management") return addDefault(table_settings_user_management);
    if (strSettings === "team_management") return addDefault(table_settings_team_management);
    if (strSettings === "settings_integrations") return addDefault(table_settings_integrations);
    if (strSettings === "settings_groups") return addDefault(table_settings_groups);
    if (strSettings === "reporting_data") return addDefault(table_settings_reporting_calls);
    if (strSettings === "zone_profile") return addDefault(table_settings_zone_profile);
    if (strSettings === "zone_profile_zones") return addDefault(table_settings_zone_profile_zones);
    if (strSettings === "residents_table") return addDefault(table_residents_list);
    return defaultSettings;
  }

  function getTitleFromSettingsType(strSettings) {

    if (strSettings === "live_calls") return "Todays_Activity";
    if (strSettings === "devices") return "Devices";
    if (strSettings === "user_management") return "User_Management";
    if (strSettings === "team_management") return "Team_Management";
    if (strSettings === "settings_integrations") return "Integrations";
    if (strSettings === "settings_groups") return "Groups";
    if (strSettings === "reporting_data") return "Reports";
    if (strSettings === "zone_profile") return "Zone_Profile";
    if (strSettings === "zone_profile_zones") return "Zone_Profile";
    return "connect_export";
  }


  const table_settings_zone_profile_zones = {
    enableRowActions: false,
    enableRowSelection: false,
    enablePagination: false,
    enableColumnFilters: false,
    enableSorting: false,
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    positionActionsColumn: 'last',
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableHiding: false,
    enableGlobalFilter: false,
    initialState: { columnVisibility: { id: false } },
    renderTopToolbarCustomActions: ({ table }) => (
      <div className='flex-row'>
        <TopMenu tableData={table}>
          {/*<button className='button_aqtable'>Add Zone</button>*/}
        </TopMenu>
      </div>),
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [rowID, setRowID] = useState(null);

  const handleMenuOpen = (event, id) => {
    setAnchorEl(event.target);
    setRowID(id)
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setRowID(null);
  };
  function isNHSValid(nhs) {
    return nhs.length === 10 && !isNaN(nhs) && nhs.trim() === nhs;
  }
  const table_residents_list = {

    ...MasterTableSettings,
    renderRowActions: ({ row,
      //closeMenu 
    }) => (
      <div className='buttonLayout'>
        <AQToolTip className="menuButton" tooltip={<div className="tooltipStyle">View In Nourish</div>} >
          <IconButton className='menuButton'
            color="primary"
            onClick={() => window.open(row.original.clientSystemUrl)}
          >
            <Link style={{ color: 'gray' }} />
          </IconButton>
        </AQToolTip>
        <IconButton color='primary' className=' menuButton' onClick={(t) => handleMenuOpen(t, row.original.id)}>
          <svg className='ThreeDots' color='#383636' class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="MoreHorizIcon"><path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2"></path></svg>
          {/* <p className='ThreeDots'>...</p> */}
        </IconButton>

        <Menu
          className="ActionMenuShadow" i
          anchorEl={anchorEl}
          open={Boolean(rowID === row.original.id)}
          onClose={handleMenuClose}
        >
          <MenuItem
            key={0}
            onClick={() => {
              handleMenuClose();
              // setModalElement(<UserDetails onClose={closeModal}  user={row.original} siteID={siteID} dataRefresh={dataRefresh} options={options}/>)
              setModalElement(<NourishResidentDetails onClose={closeModal} userData={row.original} siteID={siteID} dataRefresh={dataRefresh} options={options} />)

            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon><AccountCircle /></ListItemIcon>
            View Details
          </MenuItem>
          {console.log()}
          <MenuItem
            key={0}
            onClick={() => {
              // setSelectedResident(row.original);
              handleMenuClose();
              setModalElement(<NourishResidentEdit onClose={closeModal} userData={row.original} siteID={siteID} dataRefresh={dataRefresh} options={options} />)
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon><Edit /></ListItemIcon>
            Edit Details
          </MenuItem>

          <MenuItem
            key={0}
            onClick={() => {
              // setSelectedResident(row.original);
              handleMenuClose();
              setModalElement(<UserDetailsResident onClose={closeModal} user={row.original} siteID={siteID} dataRefresh={dataRefresh} options={options} />)
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon><Edit /></ListItemIcon>
            Edit old
          </MenuItem>
        </Menu>
      </div>
    ),


    renderTopToolbarCustomActions: ({ table }) => (
      <div className='flex-row'>

        <ExportButton tableData={table} />
      </div>
    ),
  };

  const table_settings_devices = {
    ...defaultSettings,
    initialState: { columnVisibility: { move_id: false, groupid: false }, pagination: { pageSize: 50, pageIndex: 0 } },
    muiTableHeadCellProps: { sx: { fontWeight: 'bold', fontSize: _tableHeaderFontSize, }, },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '', //change header text
        //size: 300, //make actions column wider
      },
    },
    /*
      renderRowActionMenuItems: ({ closeMenu }) => [
        <>
        <MenuItem
          key={0}
          onClick={() => {
            // View profile logic...
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <AccountCircle />
          </ListItemIcon>
          View Device
        </MenuItem>,
        </>
      ],
    */
    renderTopToolbarCustomActions: ({ table }) => (
      <div className='flex-row'>
        {/*<button className='button_aqtable'>Add Device</button>&nbsp;*/}
        <ExportButton tableData={table} />
      </div>),

  };

  const table_settings_integrations = {
    ...defaultSettings,
    initialState: { columnVisibility: { id: false }, pagination: { pageSize: 50, pageIndex: 0 } },
    renderRowActionMenuItems: ({ closeMenu }) => [
      <>
        <MenuItem
          key={0}
          onClick={() => {
            setModalElement(<CreateAPI onClose={closeModal} data={data} />);
            closeMenu();
          }}
          sx={{ m: 0 }}>
          <ListItemIcon>
            <Key />
          </ListItemIcon>
          Generate New API Key
        </MenuItem>
      </>
    ],
    renderTopToolbarCustomActions: ({ table }) => (
      setRowCount(table),
      <div className='flex-row'>
        <button
          className='button_aqtable'
          onClick={() => {
            setModalElement(<NewIntegration onClose={closeModal} data={data} siteID={siteID} />);
          }}
        >Create new integration</button>&nbsp;
        <ExportButton tableData={table} />
      </div>)

  };

  const table_settings_groups = {
    ...defaultSettings,
    initialState: { columnVisibility: { id: false }, pagination: { pageSize: 50, pageIndex: 0 } },
    renderRowActionMenuItems: ({ row, closeMenu }) => [
      <>
        <MenuItem
          key={0}
          onClick={() => {
            setModalElement(<EditGroup data={data[row.id]} onClose={closeModal} siteID={siteID} />);
            closeMenu();
          }}
          sx={{ m: 0 }}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          Edit group
        </MenuItem>
        <MenuItem
          key={0}
          onClick={() => {
            setModalElement(<DeleteGroup data={data[row.id]} onClose={closeModal} />);
            closeMenu();
          }}
          sx={{ m: 0 }}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Delete group
        </MenuItem>
      </>
    ],

    renderTopToolbarCustomActions: ({ table }) => (
      <div className='flex-row'>
        <button
          className='button_aqtable'
          onClick={() => {
            console.log('checkA', data)
            setModalElement(<NewGroups onClose={closeModal} data={data} siteID={siteID} />);
          }}
        >Create new group</button>&nbsp;
        <ExportButton tableData={table} />
      </div>)
  };

  const table_settings_team_management = {
    ...defaultSettings,
    initialState: { columnVisibility: { id: false, siteID: false, groupid: false }, pagination: { pageSize: 50, pageIndex: 0 } },
    renderRowActionMenuItems: ({ row, closeMenu }) => [
      <>
        <MenuItem
          key={1}
          onClick={() => {
            console.log('user details', data)
            console.log(data[row.id])
            setModalElement(<UserEdit onClose={closeModal} siteID={siteID} userData={data[row.id]} />);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          Edit details
        </MenuItem>,
        <MenuItem
          key={1}
          onClick={() => {
            setModalElement(<ResetPasswordForm id={data[row.id].email} onClose={closeModal} />);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <Send />
          </ListItemIcon>
          Reset Password
        </MenuItem>,

        <MenuItem
          key={0}
          onClick={() => {
            setModalHeight("200px")
            setModalElement(<DeleteDialog name={data[row.id].surname} email={data[row.id].email} userID={data[row.id].id} onClose={closeModal} />)
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Delete
        </MenuItem>,
      </>
    ],
    renderTopToolbarCustomActions: ({ table }) => (
      <div className='flex-row'>
        <button
          className='button_aqtable'
          onClick={() => {
            setModalElement(<NewUserPage onClose={closeModal} siteID={siteID} canAssign={false} />);
          }}
        >Create new user</button>&nbsp;
        <ExportButton tableData={table} />
      </div>),
  };

  const table_settings_user_management = {
    ...defaultSettings,
    initialState: { columnVisibility: { id: false, siteID: false, groupid: false }, pagination: { pageSize: 50, pageIndex: 0 } },
    renderRowActionMenuItems: ({ row, closeMenu }) => [
      <>
        <MenuItem
          key={0}
          onClick={() => {
            setModalElement(<UserDetails id={data[row.id].email} details={data[row.id]} onClose={closeModal} />);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <AccountCircle />
          </ListItemIcon>
          View Details
        </MenuItem>,

        <MenuItem
          key={1}
          onClick={() => {
            console.log('user details', data)
            console.log(data[row.id])
            setModalElement(<ConnectUserEdit onClose={closeModal} siteID={siteID} userData={data[row.id]} />);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          Edit details
        </MenuItem>,

        <MenuItem
          key={1}
          onClick={() => {
            setModalElement(<ResetPasswordForm id={data[row.id].email} onClose={closeModal} />);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <Send />
          </ListItemIcon>
          Reset Password
        </MenuItem>,
        <MenuItem
          key={3}
          onClick={() => {
            //deleteUser(data[row.id].email);
            setModalHeight("200px")
            setModalElement(<DeleteDialog name={data[row.id].surname} userID={data[row.id].id} email={data[row.id].email} onClose={closeModal} />)
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Delete
        </MenuItem>,
      </>
    ],
    renderTopToolbarCustomActions: ({ table }) => (
      <div className='flex-row'>
        <button
          className='button_aqtable'
          onClick={() => {
            setModalElement(<NewUserPage onClose={closeModal} siteID={null} canAssign={true} />);
          }}
        >Create new user</button>&nbsp;
        <ExportButton tableData={table} />
      </div>),
    // renderBottomToolbarCustomActions: ({ table }) => (
    //   console.log('check', table),
    //   <div style={{fontSize: '12px', font: 'Roboto'}}>
    //     Total Items: {table.options.data.length}
    //   </div>
    // ),
    // muiPaginationProps: {
    //   sx: {
    //     '.MuiTablePagination-displayedRows': {
    //       fontSize: '12px', // Change this value to the desired font size
    //     },
    //     '.MuiTablePagination-select': {
    //       fontSize: '12px', // Change this value to the desired font size for the select element
    //     },
    //     '.MuiTablePagination-selectLabel': {
    //       fontSize: '50px', // Change this value to the desired font size for the select label
    //     },
    //   },
    // },
  };

  const table_settings_zone_profile = {
    initialState: {
      showColumnFilters: true,
      columnVisibility: { id: false },
      pagination: { pageSize: 50, pageIndex: 0 }
    },
    enableFullScreenToggle: false,
    renderRowActionMenuItems: ({ row, closeMenu }) => [
      <>

        <MenuItem
          key={0}
          onClick={() => {
            // View profile logic...

            setModalElement(<EditZoneProfile data={data[row.id]} />)

            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Edit
        </MenuItem>,
      </>
    ],
    enableRowSelection: false,
    renderTopToolbarCustomActions: ({ table }) => (
      <TopMenu tableData={table}>
        {/*<button 
          onClick={()=>{
            setModalElement(
              <NewZoneProfilePage onClose={closeModal}></NewZoneProfilePage>
            );
          }}
        className='button_aqtable'>New Profile</button>
        */}
      </TopMenu>
    ),
  };

  const table_settings_reporting_calls = {
    initialState: {
      showColumnFilters: true,
      columnVisibility: { AQRef: false, recID: false, JourneyRef: false, SiteID: false, Site: false, duration: true },
      pagination: { pageSize: 50, pageIndex: 0 }
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '', //change header text
        //size: 300, //make actions column wider
      },
    },
    enableRowSelection: true,
    renderTopToolbarCustomActions: ({ table }) => (<ExportButton tableData={table} />),
  };

  const table_settings_live_calls = {
    ...defaultSettings,
    initialState: { pagination: { pageSize: 50, pageIndex: 0 } },
    renderTopToolbarCustomActions: ({ table }) => (<ExportButton tableData={table} />),
  };

  const IconExport = () => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15" /></svg>

  /*function getProcessor(val){
    if (val==="") return "";
    return val;
  }*/

  const ExportButton = ({ tableData }) => {

    const [exportData, setExportData] = useState(null);

    return (
      <>
        {
          (exportData !== null) &&
          <ExportHandler title={"data"} data={exportData} onClose={() => setExportData(null)} masterData={data} show={(exportData !== null)} processor={settingsType} />
        }
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            padding: '8px',
            flexWrap: 'wrap',
          }}
        >
          <Button
            onClick={() => {
              const rows = ((tableData.getIsAllRowsSelected()) ? tableData.getSelectedRowModel().rows : tableData.getSelectedRowModel().rows);
              const rowData = rows.map((row) => row.original);
              if ((rows !== null) && (rows.length > 0)) {
                setExportData(cleanData(rowData));
              }
              else {
                setExportData(cleanData(data));
              }
              //handleExportRows((tableData.getIsAllRowsSelected())?tableData.getSelectedRowModel()  .rows:tableData.getSelectedRowModel().rows)
            }}
            //onClick={() => handleExportRows((tableData.getIsAllRowsSelected())?tableData.getSelectedRowModel()  .rows:tableData.getSelectedRowModel().rows)}
            disabled={tableData.getSelectedRowModel().rows.length === 0}
            startIcon={<FileDownloadIcon />}
          ></Button>
        </Box>
      </>
    );
  }

  const table = useMaterialReactTable({ columns, data, initialState: { pagination: { pageSize: 50, pageIndex: 0 } }, ...getTableSettings(settingsType) });

  function DeleteDialog({ name, email, userID, onClose }) {
    console.log(email)
    return (
      <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
        <div className='aqForm' style={{ height: '180px' }}>
          <h3>
            Delete User
          </h3>
          <div>
            Are you sure you want to delete {email}'s account?<br />
            This action cannot be undone.
          </div>
          <div className='submit_fields'>
            <div>
              <button className='clear_button' onClick={() => deleteUser(email, userID)}>Delete</button>
              <button className='close_buton' onClick={onClose} >Close</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  function deleteUser(username, userID) {
    console.log('deleting', username)
    if (username === "") return;
    apiPost("api/messages/user/delete",
      {
        "username": username,
        "userID": userID,
      },
      (d) => {
        if (dataRefresh) dataRefresh();
        enqueueSnackbar("User deleted successfully", { variant: "success" });
        closeModal();
      },
      (e) => { console.log(e) }
    )
  }

  function closeModal() {
    setModalHeight(null)
    setModalElement(null);
    if (onUpdateRefreshPath !== undefined) {
      if (dataRefresh) dataRefresh();
    }
    else {
      if (dataRefresh) dataRefresh();
    }
  }

  return (
    <>
      {
        (modalElement !== null)
        &&
        <AQModal show={true} onClose={closeModal} height={modalHeight}>
          {/* {console.log(modalHeight)} */}
          {modalElement}
        </AQModal>
      }
      <MaterialReactTable table={table} />
    </>
  );

};

export const MasterTableSettings = {
  selectAllMode: "all",
  enableRowActions: true,
  enableRowSelection: true,
  columnFilterDisplayMode: 'popover',
  // paginationDisplayMode: 'pages',
  positionToolbarAlertBanner: 'bottom',
  positionActionsColumn: 'last',
  enableFullScreenToggle: false,
  //manualSorting: true,  //this caused the filtering to stop on the pages
  muiTableHeadCellProps: {
    //simple styling with the `sx` prop, works just like a style prop in this example
    sx: {
      fontWeight: 'bold',
      fontSize: _tableHeaderFontSize,
      color: "gray"
    },
  },

  muiTableBodyRowProps: ({ row }) => ({
    //conditionally style selected rows
    sx: {
      fontWeight: row.getIsSelected() ? 'bold' : 'normal',
      fontSize: _tableBodyFontSize,
      color: "gray"
    },
  }),
  muiTableBodyCellProps: ({ column }) => ({
    //conditionally style pinned columns
    sx: {
      fontWeight: column.getIsPinned() ? 'bold' : 'normal',
      fontSize: _tableBodyFontSize,
      FontFace: "sans-serif",
      border: 0,
      color: "gray"
    },
  }),
  displayColumnDefOptions: {
    'mrt-row-actions': {
      header: '', //change header text
      //size: 300, //make actions column wider
    },
  },
  muiTableBodyProps: {
    /*
    ADC to go live
    sx: {
      //stripe the rows, make odd rows a darker color
      '& tr:nth-of-type(odd) > td': {
        backgroundColor: '#f5f5f5',
      },
    },
    */
  },
  muiBottomToolbarProps: {

  },

  initialState: {
    pagination: {
      pageSize: 50,
      pageIndex: 0, // Optional: you can set the initial page index if needed
    },
  },


  muiPaginationProps: {
    // defaultPage:{

    // }


    rowsPerPageOptions: [10, 25, 50, 100],
    // showFirstButton: true,
    // showLastButton: true,
    // color: 'primary',
    // shape: 'rounded',
    showRowsPerPage: true,
    // count: {global_rowCount}
    // variant: 'outlined',

    sx: {
      fontSize: '15px',
      button: {
        fontWeight: 'bold',
        fontSize: "15px",
      },
      backgroundColor: '#f5f5f5',
    },

  },
};

export default AQDataTable;