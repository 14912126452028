import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DatePicker, Space } from 'antd';


export default function AQDateRangePicker({start, end, onChange}) {
  
  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      if (onChange) onChange(dates[0].startOf("day").format(), dates[1].endOf("day").format())
      console.log(dates[0].format());
      console.log(dates[1].format());
    } else {
      console.log('Clear');
    }
  };

  const startDate = (start!==undefined)?dayjs(start, dateFormat).startOf('day'): dayjs().startOf('day');
  const endDate = (end!==undefined)?dayjs(end, dateFormat).endOf('day'): dayjs().endOf('day');

      return (
        <div>
        <RangePicker
          defaultValue={[startDate, endDate]}
          presets={[
            {
              label: <span aria-label="Current Time to End of Day">Today</span>,
              value: () => [dayjs().startOf('day'), dayjs().endOf('day')], // 5.8.0+ support function
            },
            ...rangePresets,
          ]}
          format="DD/MM/YYYY"
          onChange={onRangeChange}
        />
        </div>
      );
}

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;


    const rangePresets = [
      { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
      { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
      { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
      { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
      { label: 'Last 6 Months', value: [dayjs().add(-182, 'd'), dayjs()] },
      { label: 'Last Year', value: [dayjs().add(-364, 'd'), dayjs()] },
    ];

    const dateFormat = 'YYYY/MM/DD';