import AQDataTable from "../AQDataTable/AQDataTable";
import { CallDataTable } from "../CallDataTable";
import { columns_template_livecalls } from "../AQDataTable/columns_templates";

export const DataModal = ({ title, selectedData, closeModal, columns }) => {

  function getTitle(txt) {
    if (txt === "") return txt;
    if (txt !== "All Calls") return `"${txt}" Calls`;
  }

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div><h2>{title}</h2></div>
        <div style={{ height: "500px", overflowY: "scroll" }}>
          {(selectedData !== null) && <AQDataTable style={{ overflowX: 'hidden' }} data={selectedData} columns={columns} settingsType={"live_calls"} />}
        </div>
        <button className="marginTopBottom5 floatRight button_1" onClick={closeModal}>Close</button>
      </div>
    </div>
  );
};

export const InsightDataModal = ({ title, selectedData, closeModal, columns, setSelectedData }) => {

  const handleClose = () => {
    setSelectedData(null)
    closeModal()
  }

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div style={{ textAlign: 'center' }}><h2>{title}</h2></div>
        <div style={{ height: "500px", width:'1500px', overflowY: "scroll" }}>
          {(selectedData !== null) && <AQDataTable style={{ overflowX: 'hidden' }} data={selectedData} columns={columns} settingsType={"live_calls"} />}
        </div>
        <button className="marginTopBottom5 floatRight button_1" onClick={handleClose}>Close</button>
      </div>
    </div>
  );
};


export const OpenModal = (props) => {

  // function getTitle(txt) {
  //   if (txt === "") return txt;
  //   if (txt !== "All Calls") return `"${txt}" Calls`;
  // };

  // const formSubmitButton = (formID) => {
  //   if (formID === undefined || formID == '')
  //     return (<></>);
  //   else
  //     return (
  //       <button form={{ formID }} className='validate_button' type="submit">Update user</button>
  //     )
  // }

  return (
    <div className="modal-overlay">
      <div className="modal">
        {/*<div><h2>{props.title}</h2></div>*/}
        <div className="innerLayer">
          {props.children}
        </div>
        {/*
      <button className="marginTopBottom5 floatRight button_1 close_button" onClick={props.closeModal}>Close</button>
      */}
      </div>
    </div>
  );
}