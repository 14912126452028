import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useSnackbar } from 'notistack';
import { auth } from '../../data/firebase';

const UserSettingsContext = createContext();

const UserSettingsProvider = ({ children }) => {

  const { enqueueSnackbar } = useSnackbar();

  const [userSettings, setUserSettings] = useState(null);
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();
  //const { user, isAuthenticated, isLoading } = useAuth0();

  console.log("AUTHUSER", user);
  const updateSettings = (newSettings) => {
    setUserSettings(newSettings);
  };

  console.log("authddddd", user);

  useEffect(() => {

      if (user===undefined) return;
      if (user.email===undefined) return;
      const postData = {
        "username": user.email,
        "userToken": "asdaskas"
      };
      
      axios.post(
        '/api/messages/site/config',
        postData  
      )
      .then(response => { 
        const dat = response.data;

        
        console.log("success response")
        console.log(dat);
        
        if (dat.site===null) {
          const err = "Your account is not linked to a site.  Please contact Arquella Support.";
          enqueueSnackbar(err, {variant:"error"});
          //setUserSettings({"error": err})
          setUserSettings(dat);
        }
        else {
          console.log(dat);
          setUserSettings(dat);
          localStorage.setItem("dbVal", dat.site.dbCode);
          localStorage.setItem("dbPath", dat.site.dbPath);
        }
      })
      .catch(error => {
        enqueueSnackbar(error, {variant:"error jwioj"});
        setUserSettings({"error": error})
      });

    
  }, [user]);


  return (
    <UserSettingsContext.Provider value={{userSettings, updateSettings}}>
      {children}
    </UserSettingsContext.Provider>
  );
};

const useUserSettings = () => {
  const context = useContext(UserSettingsContext);
  if (!context) {
      return { "error": "User settings error." };
  }
  else {
    return context;
  }
};

export { UserSettingsProvider, useUserSettings };


