import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { AdminPage } from "./Pages/admin-page";
import { CallbackPage } from "./Pages/callback-page";
import { HomePage } from "./Pages/home-page";
import { NotFoundPage } from "./Pages/not-found-page";
import { ProfilePage } from "./Pages/profile-page";
import { ProtectedPage } from "./Pages/protected-page";
import { PublicPage } from "./Pages/public-page";
import Onboarding from "./Pages/Onboarding/Onboarding";
import "./App.css";
import UserManagement from "./Pages/Settings/UserManagement/UserManagement";
import Move from "./Pages/Move";
//import People from "./Pages/People";
//import Dashboard from "./Pages/Dashboard";
import Devices from "./Pages/Settings/UserManagement/Devices";
import Groups from "./Pages/Settings/Groups/Groups";
import RemoteCheck from "./Pages/system/monitor/remote_check";
import SystemCheckEvents from "./Pages/system/monitor/systemCheckEvents";
import SuportPage from "./Pages/system/monitor/support";
import { RunTemplate } from "./Pages/Settings/UserManagement/RunTemplate";
import Integrations from "./Pages/Settings/Integration/Integrations";
import ConnectUsers from "./Pages/Settings/ConnectUsers";
//import Monitoring from "./Pages/Settings/Monitoring";
import ReportData from "./Pages/ReportData";
import ZoneProfiles from "./Pages/Settings/ZoneProfiles";
import { useUserSettings } from "./components/UserSettingsProvider/UserSettingsProvider"
import { PageLayout } from "./components/page-layout";
import Insights from "./Pages/insights/Insights";
import { CommingSooMonitoring, CommingSoonPeople, CommingSoonSense } from "./Pages/NotSubscribed/NotSubscribed";
import { AdminSitesList } from "./Pages/system/admin/admin_sites_list";
import Move2 from "./Pages/Move2/Move2";
import Batterymonitor from "./Pages/system/monitor/BatteryMonitor";
import HeartbeatMonitor from "./Pages/system/monitor/HeartbeatMonitor";
import NourishMonitor from "./Pages/Nourish/NourishMonitor";
import ExportHandler, { ExportTester } from "./Pages/Exports/export_handle";
import { StyleGuide } from "./styles/style";
import AverageResponseChart from "./components/AverageResponseChart/AverageResponseChart";
import { ResetPassword } from "./Pages/Settings/UserManagement/ResetPassword";
export const App = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const { userSettings, updateSettings } = useUserSettings();

  if (isLoading) {
    return (
      <div className="page-layout"><PageLoader /></div>
    );
  }

  if (!isAuthenticated) {
    loginWithRedirect(); // Redirect to Auth0 login page
    return null; // or a loading spinner while redirecting
  }

  if (!userSettings) {
    return <PageLayout><div>Loading...</div></PageLayout>;
  }
  else {
    console.log({ userSettings });
    if (Object.keys(userSettings).includes("error")) {
      return <PageLayout><div>{userSettings.error}</div></PageLayout>;
    }

    if ((userSettings.site === undefined) || (userSettings.site === null)) {
      return <PageLayout><div>Your account needs configuring in Arquella Connect.  Please contact Arquella Support to configure this for you.</div></PageLayout>;
    }
  }

  // if (userObject===null) return <div>Getting User Details<br /><PageLoader /></div>;

  return (
    <PageLayout>
      <Routes>
        <Route path="/" element={<AuthenticationGuard component={HomePage} />} />
        <Route path="/lowmaintenance" element={<AuthenticationGuard component={HomePage} />} />
        <Route path="/live" element={<AuthenticationGuard component={HomePage} />} />
        <Route path="/teams" element={<AuthenticationGuard component={UserManagement} />} />
        <Route path="/onboarding" element={<AuthenticationGuard component={Onboarding} />} />
        <Route path="/profile" element={<AuthenticationGuard component={ProfilePage} />} />
        <Route path="/device list" element={<AuthenticationGuard component={Devices} />} />
        <Route path="/groups" element={<AuthenticationGuard component={Groups} />} />
        {//<Route path="/residents" element={<AuthenticationGuard component={CommingSoonPeople} />}/>
        }

        <Route path="/public" element={<PublicPage />} />
        <Route path="/style" element={<StyleGuide />} />
        <Route path="/remotecheck" element={<RemoteCheck site={"magdalen"} />} />
        <Route path="/systemcheckevents" element={<SystemCheckEvents />} />
        <Route path="/reports" element={<AuthenticationGuard component={ReportData} />} />
        <Route path="/insights" element={<AuthenticationGuard component={Insights} />} />
        <Route path="/battery levels" element={<AuthenticationGuard component={Batterymonitor} />} />
        <Route path="/heartbeat monitoring" element={<AuthenticationGuard component={HeartbeatMonitor} />} />
        <Route path="/residents" element={<AuthenticationGuard component={NourishMonitor} />} />
        <Route path="/excelexample" element={<AuthenticationGuard component={ExportTester} />} />
        <Route path="/move" element={<Move />} />
        <Route path="/move2" element={<Move2 />} />
        <Route path="/protected" element={<AuthenticationGuard component={ProtectedPage} />} />
        <Route path="/admin" element={<AuthenticationGuard component={AdminPage} />} />
        <Route path="/admin/siteadmin" element={<AuthenticationGuard component={AdminSitesList} />} />
        {//<Route path="/callback" element={<CallbackPage />} />//
        }
        <Route exact path="/callback" element={<Navigate to="/" />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/support" element={<SuportPage />} />
        <Route path="/fbtemplate" element={<AuthenticationGuard component={RunTemplate} />} />
        <Route path="/integrations" element={<AuthenticationGuard component={Integrations} />} />
        <Route path="/user management" element={<AuthenticationGuard component={ConnectUsers} />} />
        <Route path="/zone profiles" element={<AuthenticationGuard component={ZoneProfiles} />} />
        <Route path="/monitoring" element={<AuthenticationGuard component={CommingSooMonitoring} />} />
        <Route path="/sense" element={<AuthenticationGuard component={CommingSoonSense} />} />
        {/* <Route path="/resetpassword" element={<ResetPassword />} /> */}

      </Routes>
    </PageLayout>
  );
};


export default App;