export function setupServiceWorker() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        // Registration successful
        console.log('Service worker registered:', registration);
      })
      .catch(error => {
        // Registration failed
        console.error('Service worker registration failed:', error);
      });
  }

}
