import React, { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import './BarChart.css';

// Register the components you need from Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({datasets, options}) => {
    
  const data = {
    labels: datasets.map(d=>d.zone),
    
    datasets: [
        {
          label: 'Calls',
          data: datasets.map(d=>d.calls),
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
          cursor: 'circle',
          hoverBackgroundColor: 'rgba(75, 255, 255, 0.9)'
        },
      ],

  };

  return <Bar className='custom_barChart' data={data} options={options} />;

};

export default BarChart;
