import { useState } from "react";
import { Checkbox } from '@mui/material';
import { apiPost } from "../../data/apidata";

const ZoneProfileCheckBox=({profileID, zoneID, field, initialValue})=>{

    const [value, setValue] = useState(initialValue);

    function updateZoneCall(value){
        apiPost(
            "/api/messages/zoneprofiles/zone/edit",
            {
                "profileID": `${profileID}`,
                "zoneID": `${zoneID}`,
                "fieldName": `${field}`,
                "fieldValue": `${value}`
            },
            (d)=>{
                console.log("updated", d);
            },
            (e)=>{
                console.log("error", e);
            }
        )
    }

    function handleChange(){
        const newVal = !value;
        updateZoneCall(newVal);
        setValue(newVal);
    }

    return(
        <Checkbox 
            sx={CheckBoxStyle}
            checked={value}
            onChange={handleChange}
        ></Checkbox>
    );
}

export const CheckBoxStyle = {
    color: "#4cc1bd",
    '&.Mui-checked': { color: "#4cc1bd",},
    '& .MuiSvgIcon-root': { fontSize: 19 }
    };

export default ZoneProfileCheckBox;