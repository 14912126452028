import React, { Component } from 'react';
import { raiseError } from './functions/error_handling';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      error: ""
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ 
      hasError: true,
      error: error
    });
    raiseError("", "ErrorBoundary", "Caught error", error.toString().subString(0, 100), false);
  }
  
  render() {

    if (this.state.hasError) {
      return ( 
        <div>
            <div>An unexpected error has occurred.</div>
            <div>The details of this error has been reported to Arquella and will be invesitgated.</div>
            <div>If you continue to see this error then please report this to Arquella.</div>
            <div>{this.state.error.toString()}</div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;