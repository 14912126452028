// src/components/StackedBarChart.js
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { getColour, getDaysArray, groupBy } from '../../functions/objects_arrays';
// Register the components you need from Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TotalCallsChart = ({calls, start, end}) => {

    const [data, setData] = useState(null);
    
    function sumDurationByDate(objects, dates) {
      // Create an object to store the sum of durationSeconds for each date
      const dateDurations = {};
    
      // Initialize dateDurations with zero for each date in the dates array
      dates.forEach(date => {
        dateDurations[date] = 0;
      });
    
      // Sum the durationSeconds for each date
      objects.forEach(obj => {
        const objDate = obj.StartDate;
        if (dateDurations.hasOwnProperty(objDate)) {
          dateDurations[objDate] += 1;
        }
      });
    
      // Create the data array from dateDurations
      const data = dates.map(date => dateDurations[date]);
    
      // Return the final object
      return data;
    }

    function getGroupedDataset(groupedCalls, datesArr){
      const rtnObject = Object.keys(groupedCalls).map((k=>{
        return {
          label: k,
          data: sumDurationByDate(groupedCalls[k], datesArr),
          backgroundColor: getColour(k)            
        };
      }))
      return rtnObject;
    }

    useEffect(()=>{
        const groups = groupBy(calls, "callType");
        const datesArr = getDaysArray(start, end);
        
        let dat = {
            labels: datesArr,
            datasets: getGroupedDataset(groups, datesArr)
        };
        console.log("datesArr", dat);
        setData(dat);      

    }, [calls]);
    

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: { display: false },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  const containerStyle = {
    height: '300px',
    width: "100%"
    // Set your desired height here
  };

  if ((data===null)||(options===null)) return <></>;

  return (
    <div style={containerStyle}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default TotalCallsChart;
