import { Card } from "@tremor/react";
import { useEffect, useState, useRef } from "react";
import {Title as TTitle, Text, Flex } from "@tremor/react";
import { displayDateFromUTC, displayDateOrTime } from "../functions/dataHandler";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { getDatasetAtEvent, getElementAtEvent, getElementsAtEvent } from 'react-chartjs-2';
import { placeTotalCallsReport } from "../functions/apiActions";
import { externalTooltipHandler } from "./externalTooltipHandler";
import SpinnerLoader from "./SpinnerLoader";
import { DataBreakDown } from "./DataBreakDown";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function ChartCard({ postFilters, scaleType }){
 
  const [cData, setCData] = useState(null);
  const chartRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dataBreakDown, setDataBreakDown] = useState(null);
    
    function dataFormatter(val){
      return val.toString();
    }
    
    function handleBarClick(elements) {
      console.log("handleClick", elements);
      try {
        const dataSetIndex = elements[0].datasetIndex;
        const elementIndex = elements[0].index;
        const dataset = cData.datasets;
        const rawData = dataset[dataSetIndex].rawData[elementIndex];
        setDataBreakDown(rawData);
      }
      catch (e) { console.log(e); }
    }

    const options = {
      onClick: (_, elements) => {
        handleBarClick(elements);
      },
      plugins: {
        title: { display: false },
        legend: { display: false },
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: externalTooltipHandler
        },
      },
      responsive: true,
      scales: {
        x: {
          type: 'category',
          stacked: true,
          grid: {
            display:false
          },
          ticks: {
            callback: function(value, index, ticks) {
              //if (cData.labels[index].length>5) return cData.labels[index];
              if (cData.labels.length<=24) return cData.labels[index];
              if (cData.labels.length<=72) {
                if (index % 6 === 0) return cData.labels[index];
              }
              if (index % 6 === 0) return cData.labels[index];
              //if (index % 10 === 0) return displayDateFromUTC(new Date(cData.labels[index]))//data[value];//displayDateFromUTC(data[value].Date);
            }
          }
        },
        y: {
          stacked: true,
          grid: {
            display:true
          },
          ticks: {
            beginAtZero: true,
            callback: function(value) {if (value % 1 === 0) {return value;}}
          }   
        },
      },
    };

  useEffect(()=>{
    if (!isLoading) setIsLoading(true);
    placeTotalCallsReport(postFilters,
      (d)=>{
        //if (scaleType==="hour") {
        if (d.labels.length<=72) {
          d.labels = d.labels.map((l, counter)=>{
            if (counter===0) return displayDateFromUTC(l);
            return displayDateOrTime(l);
          }); 
        } 
        else {
          d.labels = d.labels.map(l=>displayDateFromUTC(l)); 
        }
        
        setCData(d);
        setIsLoading(false);
      }
      )

  },[postFilters]);

  function updateBreakdown(){ setDataBreakDown(null);  }

  return (
    <Card>
      <div className="md:flex justify-between">

        {(dataBreakDown!==null) && <DataBreakDown preData={dataBreakDown} onClose={updateBreakdown} />}
          <div>
            <Flex className="space-x-0.5" justifyContent="start" alignItems="center">
              <TTitle>Total Calls</TTitle>
            </Flex>
            <Text></Text>
          </div>
        </div>
        {
        (isLoading) 
        ? <SpinnerLoader isLoading={true} />
        :
        <div className="w-full max-h-100">
          {(cData!==null)&&
              <Bar
              id="CallsBarChart"
              ref={chartRef}
              width={"800px"}
              options={options}
              data={cData}
            />
          }
        </div>
        }
  </Card>
);

}





