import { initializeApp } from 'firebase/app';
import { getDatabase, onValue, ref, get, set, push} from "firebase/database";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";


function getConfig(val){
    /*if (val=="1") return {
        apiKey: "AIzaSyCOXJtROQlSTEJwcB7WTOx97znL4HswvUg",
        authDomain: "arquella-cc76e.firebaseapp.com",
        databaseURL: "https://arquella-cc76e.firebaseio.com",
        projectId: "arquella-cc76e",
        storageBucket: "arquella-cc76e.appspot.com",
        messagingSenderId: "673271376643",
        appId: "1:673271376643:web:b55b0f03b976e5d0c21736",
        measurementId: "G-10LF2R4MV2"
    };

    if (val=="2") return {
        apiKey: "AIzaSyCOXJtROQlSTEJwcB7WTOx97znL4HswvUg",
        authDomain: "arquella-cc76e.firebaseapp.com",
        databaseURL: "https://arquella-cc76e-afe91.europe-west1.firebasedatabase.app",
        projectId: "arquella-cc76e",
        storageBucket: "arquella-cc76e.appspot.com",
        messagingSenderId: "673271376643",
        appId: "1:673271376643:web:63824a897c34e86ac21736",
        measurementId: "G-6JFJ0LGN32"
    };

    if (val=="3") return {
        apiKey: "AIzaSyCOXJtROQlSTEJwcB7WTOx97znL4HswvUg",
        authDomain: "arquella-cc76e.firebaseapp.com",
        databaseURL: "https://arquella-cc76e-39485.europe-west1.firebasedatabase.app",
        projectId: "arquella-cc76e",
        storageBucket: "arquella-cc76e.appspot.com",
        messagingSenderId: "673271376643",
        appId: "1:673271376643:web:63824a897c34e86ac21736",
        measurementId: "G-6JFJ0LGN32"
    };
*/

    return {
        apiKey: "AIzaSyCOXJtROQlSTEJwcB7WTOx97znL4HswvUg",
        authDomain: "arquella-cc76e.firebaseapp.com",
        databaseURL: val,
        projectId: "arquella-cc76e",
        storageBucket: "arquella-cc76e.appspot.com",
        messagingSenderId: "673271376643",
        appId: "1:673271376643:web:b55b0f03b976e5d0c21736",
        measurementId: "G-10LF2R4MV2"
    };
}           


const dbVal = localStorage.getItem("dbVal");
const dbPath = localStorage.getItem("dbPath");

// Initialize Firebase
export const app = initializeApp(getConfig(dbPath));
export const auth = getAuth(app);
export const login=(userName, password)=>signInWithEmailAndPassword(auth, userName, password);

export const checkLogin=()=>{
    return new Promise(async (resolve, reject)=>{
        if (auth.currentUser===null){
            login("andrewchapman@arquella.co.uk", "arquella");
            resolve("Logged in.");
        }
        else { resolve("Already logged in."); }
    });
}
/*
export const login=(userName, password)=>signInWithEmailAndPassword(auth, userName, password).then((u)=>{
    console.log("we're logged in");
});
*/

export async function getOnValue(strDBRef, returnOnValue, returnOnError){
    await checkLogin();
    const database = getDatabase(app);
    const dbRef = ref(database, strDBRef);
    onValue(dbRef, 
        (snapshot)=>{returnOnValue(snapshot.val()); },
        (error)=>{ returnOnError(error); }
    );
}


export async function getOnceValue(strDBRef, returnOnValue, returnOnError){
    await checkLogin();
    const database = getDatabase(app);
    const dbRef = ref(database, strDBRef);

    get(dbRef)
        .then((snapshot)=>{returnOnValue(snapshot.val()); })
        .catch((error)=>{ returnOnError(error); });
}



export async function setValue(strDBRef, value, returnOnValue, returnOnError){
    await checkLogin();
    const database = getDatabase(app);
    const dbRef = ref(database, strDBRef);
    if (strDBRef==="") return;
    if (strDBRef==="/") return;
    set(dbRef, value)
        .then(()=>{returnOnValue("Complete")})
        .catch((error)=>{ returnOnError(error); });
}

export async function pushValue(strDBRef, value, returnOnValue, returnOnError){
    await checkLogin();
    const database = getDatabase(app);
    const dbRef = ref(database, strDBRef);
    if (strDBRef==="") return;
    if (strDBRef==="/") return;
    push(dbRef, value)
        .then(()=>{ returnOnValue("Success"); })
        .catch((error)=>{ returnOnError(error); });
}