import React, { useEffect, useState } from "react";
import './AverageResponseChart.css';
import CallReportContainer from './CallReportContainer';
import { groupBy, betweenDates, getAverage, groupByTwo } from "../../functions/objects_arrays";
import BarChartKPI from './BarChartKPI';
import CallTypeToggle from "./CallTypeToggle";

const AverageResponseChart=(props)=> {

  const [cData, setCData] = useState([]);//props.data ? props.data : [];
  
  const [callType, setCallType] = useState("Emergency");
    
  const [filters, setFilter] = useState({
    startDate : props.startDate,
    endDate : props.endDate
  });
  
  const style = {
      width: "100%",
      display:"flex",
      flexDirection:"row",
      justifyContent: "space-between"
  }


  function reverseArray(arr) {
    return arr.reverse();
  }


  function applyWeekFilter(allData, start, end, callType) {
    
    if ((!Array.isArray(allData))||(allData.length===0)) return [];
    allData = allData.filter(d=>d["callType"]===callType);
    
    let rData = [];

    const conData = groupByTwo(allData, d=>d["StartDate"]);
    let conArr = Array.from(conData, ([name, value]) => ({ name, value }));

    conArr = reverseArray(conArr);

    conArr.forEach(c=>{
    
      const cValue = c.value.map(d=>d["durationSeconds"]).reduce((a, b) => a + b, 0);
      const cCount = c.value.length;
      let cAvr = getAverage(cValue, cCount);
      let cData = c.value;
      rData.push({
        zone: c.name,
        calls: cAvr,
        data: cData,
      });

    });
    
    return rData;
  }

    
    useEffect(()=>{
        let dta;
        dta = applyWeekFilter(props.data, props.startDate, filters.endDate, callType);
        setCData(dta);
    }, [props.data, callType])

    return(    
      <CallReportContainer
        callType={callType}
        title={
          <div style={style} >
            <div style={{alignSelf: "center"}}>{callType + " Average Response Times"}</div>
            <CallTypeToggle value={callType} callBack={setCallType} />
          </div>
        }
      >
      {(cData.length>0)
      ?
        <BarChartKPI 
        chartType="bar" 
        data={cData}
        xLabel="Date" 
        yLabel="Average Time (mins)" 
      />
      : null}
    </CallReportContainer>
    );
    
}


export default AverageResponseChart;