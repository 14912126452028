import { useEffect, useState } from 'react';
import './JourneyBar.css';
import AQToolTip from '../../ToolTip/ToolTip';
import { lCase } from '../../../functions/string';
import { getMinMax, getDifferenceInSeconds, calculatePercentageRoundUp, displayTimeFromUTC } from '../../../functions/dataHandler';
import { getEndDate, getStartDate } from '../JourneyFunctions';

export const JourneyBar = ({ journey, totalSeconds }) => {
  let totalTime = totalSeconds;
  return (
    <>
      <div className="journeybar_main_container">
        <div className="journeybar_container">

          {
            (Array.isArray(journey)) &&
            journey.map((j, i) => {

              const startDate = j.start;
              const endDate = j.end;
              const getDif = getDifferenceInSeconds(startDate, endDate);
              const per = calculatePercentageRoundUp(getDif, totalTime);

              return (
                <JourneyBarSection
                  key={`journeybar${i}`}
                  //width={per}
                  width={per}
                  record={j}
                  senseType={j.SenseType}
                  callType={lCase(j.callType)}
                  accType={j.AccessoryType}
                />
              );
            })}
        </div>
      </div>
    </>
  );
};


function getDurationDisplay(start, end) {
  const diff = getDifferenceInSeconds(start, end);
  if (diff < 60) return `${diff} seconds`;

  if (typeof diff !== 'number') {
    return "Invalid input. Please provide a number of seconds.";
  }

  const minutes = Math.floor(diff / 60);
  const remainingSeconds = diff % 60;

  return `${minutes} minute${(minutes > 1) ? "s" : ""} and ${remainingSeconds} second${(remainingSeconds > 1) ? "s" : ""}`;
}


const JourneyBarSection = ({ width, callType, record, accType, senseType }) => {
  let bg_color = callType
  const carer = (record.carer) ? record.carer : record.Carer
  // console.log(callType,senseType)
  if (lCase(senseType) === "fall emergency") {
    callType = senseType
    bg_color = 'emergency'
  }
  else if (lCase(senseType) === "fall risk") {
    callType = senseType
    bg_color = 'sense'
  }
  else if (lCase(senseType) === "fall warning") {
    callType = senseType
    bg_color = 'assistance'
  }
  return (
    <>
      <div
        style={{ "width": width + "%" }}
        className={`aqtooltip bg_${bg_color}`}>
        <AQToolTip width={width}
          tooltip={
            <div className="tooltipStyle">
              <div className='uppercasefirst'>{((accType) && (accType !== "")) ? accType : callType}</div>
              <div>{getDurationDisplay(getStartDate(record), getEndDate(record))}</div>
              <div>{(carer !== "Room Unit") ? `${carer} attended.` : ""}</div>
            </div>
          }
        ><div>&nbsp;</div></AQToolTip>
      </div>
    </>
  );
}


